import type { RegistrationParams, UnitMass } from "~/types/onboarding/registrationParams";
import type { QueryParameters } from "~/composables/useQueryParameters";

export function useExtractQueryParams() {
  const { poundsToKg, feetAndInchesToCm } = useUnitsConverter();
  const dayjs = useDayjs();

  function extractWeight(weightString: string | undefined, unit: UnitMass): number | undefined {
    if (!weightString) {
      return undefined;
    }

    const weight = Number.parseFloat(weightString);

    if (unit === "lb") {
      return poundsToKg(weight);
    }

    return weight;
  }

  function extractBodyHeight(queryParams: QueryParameters): number | undefined {
    if (queryParams.has("height")) {
      return Number.parseFloat(queryParams.getAndRemove("height"));
    }

    if (queryParams.has("ft") && queryParams.has("in")) {
      const feet = Number.parseInt(queryParams.getAndRemove("ft"));
      const inches = Number.parseInt(queryParams.getAndRemove("in"));

      return feetAndInchesToCm(feet, inches);
    }

    return undefined;
  }

  function extractDobParts(queryParams: QueryParameters) {
    if (queryParams.has("birthday")) {
      // The birthday in german format for DE funnel users.
      const [date, month, year] = queryParams.getAndRemove("birthday").split(".");
      return {
        date,
        month,
        year,
      };
    } else if (queryParams.has("byear") && queryParams.has("bmonth") && queryParams.has("bday")) {
      return {
        date: queryParams.getAndRemove("bday"),
        month: queryParams.getAndRemove("bmonth"),
        year: queryParams.getAndRemove("byear"),
      };
    }

    return null;
  }

  function extractAgeParameterToAgeRange(queryParams: QueryParameters) {
    if (queryParams.has("age")) {
      const age = parseInt(queryParams.getAndRemove("age"));
      if (age >= 16 && age <= 29) {
        return "16-29";
      } else if (age >= 30 && age <= 49) {
        return "30-49";
      } else if (age >= 50 && age <= 59) {
        return "50-59";
      } else if (age >= 70) {
        return "70+";
      }
    }
  }

  function extractDob(queryParams: QueryParameters): string | undefined {
    const parts = extractDobParts(queryParams);

    if (parts) {
      return dayjs()
        .set("date", parseInt(parts.date))
        .set("month", parseInt(parts.month) - 1) // Dayjs range is 0...11
        .set("year", parseInt(parts.year))
        .format("YYYY-MM-DD");
    }

    return undefined;
  }

  function getUndefinedMandatoryRegistrationParams(registrationParams?: Partial<RegistrationParams>) {
    const mandatoryRegParams: Record<string, unknown> = {
      userUuid: registrationParams?.userUuid,
      country: registrationParams?.country,
      goal: registrationParams?.goal,
      unitMass: registrationParams?.unitMass,
      weightGoal: registrationParams?.weightGoal,
      startWeight: registrationParams?.startWeight,
      sex: registrationParams?.sex,
      bodyHeight: registrationParams?.bodyHeight,
      dateOfBirth: registrationParams?.dateOfBirth,
    };

    const missingParams = [];

    for (const key in mandatoryRegParams) {
      if (mandatoryRegParams[key] === undefined) {
        missingParams.push(key);
      }
    }

    return missingParams;
  }

  type MandatoryRegistrationParams = Pick<
    RegistrationParams,
    "userUuid" | "country" | "goal" | "unitMass" | "weightGoal" | "startWeight" | "sex" | "bodyHeight" | "dateOfBirth"
  >;
  function hasAllMandatoryRegistrationParams(
    registrationParams: Partial<MandatoryRegistrationParams>,
  ): registrationParams is Partial<RegistrationParams> & MandatoryRegistrationParams {
    const mandatoryRegParams: Record<string, unknown> = {
      userUuid: registrationParams?.userUuid,
      country: registrationParams?.country,
      goal: registrationParams?.goal,
      unitMass: registrationParams?.unitMass,
      weightGoal: registrationParams?.weightGoal,
      startWeight: registrationParams?.startWeight,
      sex: registrationParams?.sex,
      bodyHeight: registrationParams?.bodyHeight,
      dateOfBirth: registrationParams?.dateOfBirth,
    };

    for (const key in mandatoryRegParams) {
      if (mandatoryRegParams[key] === undefined) {
        return false;
      }
    }

    return true;
  }

  return {
    hasAllMandatoryRegistrationParams,
    getUndefinedMandatoryRegistrationParams,
    extractWeight,
    extractBodyHeight,
    extractDob,
    extractAgeParameterToAgeRange,
  };
}
