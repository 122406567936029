import localeFontConfig from "~/app/locale-font.config";

export default defineNuxtRouteMiddleware(() => {
  const { locale } = useNuxtApp().$i18n;
  const localeFont = localeFontConfig[locale.value ?? "default"] ?? "latin";

  useHead({
    link: [
      {
        rel: "stylesheet",
        href: `/app/fonts/asyncfont-${localeFont}.css`,
        key: "locale-font",
      },
    ],
  });
});
