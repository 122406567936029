import { z } from "zod";
import { GoalTypeAbbrSchema } from "~/types/onboarding/onboardingGoal";
import { UnitMassSchema } from "~/types/onboarding/registrationParams";

export interface CheckoutParams {
  trial_days: number;
}

export interface GetSubscriptionPlansParams {
  segment: string;
  locale: string;
  country: string;
  user_token?: string;
}

export interface SubscriptionPlan {
  type: string;
  period: string;
  currency: string;
  price: string;
  price_raw: number;
  monthly_price: string;
  monthly_raw_price: number;
  strike_price: string | null;
  strike_price_raw: number | null;
  monthly_strike_price: string | null;
  trial_price: string | null;
  trial_price_raw: number | null;
  sku: string;
}

export interface ValidateEmailParams {
  email: string;
}

export const UserDetailsSchema = z.object({
  country: z.string(),
  is_pro: z.boolean(),
  has_newsletter: z.boolean(),
  has_confirmed_email: z.boolean(),
  email: z.string(),
  locale: z.string(),
  subscription_status: z.enum(["unknown", "cancelled", "expired", "grace_period", "will_renew", "will_expire"]),
  goal: GoalTypeAbbrSchema,
  date_of_birth: z.string(),
  uuid: z.string(),
  current_weight: z.number(),
  goal_weight: z.number().nullable(),
  unit_mass: UnitMassSchema,
});

export type UserDetails = z.infer<typeof UserDetailsSchema>;
