export default defineNuxtRouteMiddleware((to) => {
  const { $pinia } = useNuxtApp();
  const { user } = storeToRefs(useUserStore($pinia));

  if (to.path !== "/") return;

  // unauthenticated user
  if (!user.value) {
    return navigateTo("/onboarding");
  }

  // authenticated pro user
  if (user.value.premium_type) {
    return navigateTo("/account");
  }

  // authenticated non-pro user
  return navigateTo("/onboarding/checkout");
});
