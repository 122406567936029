import { z } from "zod";
import type { GoalTypeAbbr } from "~/types/onboarding/onboardingGoal";

export const SexSchema = z.enum(["male", "female"]);
export const UnitLengthSchema = z.enum(["cm", "in"]);
export const UnitMassSchema = z.enum(["kg", "lb"]);
export const UnitEnergySchema = z.enum(["kj", "kcal"]);
export const UnitGlucoseSchema = z.enum(["mmoll", "mgdl"]);
export const UnitServingSchema = z.enum(["oz", "g"]);
export const ActivityDegreeSchema = z.enum(["low", "moderate", "high", "very_high"]);
export const DietSchema = z.union([
  z.literal(null),
  z.literal("pescatarian"),
  z.literal("vegan"),
  z.literal("vegetarian"),
]);

export type Sex = z.infer<typeof SexSchema>;
export type UnitLength = z.infer<typeof UnitLengthSchema>;
export type UnitMass = z.infer<typeof UnitMassSchema>;
export type UnitEnergy = z.infer<typeof UnitEnergySchema>;
export type UnitGlucose = z.infer<typeof UnitGlucoseSchema>;
export type UnitServing = z.infer<typeof UnitServingSchema>;
export type ActivityDegree = z.infer<typeof ActivityDegreeSchema>;
export type Diet = z.infer<typeof DietSchema>;

export type RegistrationParams = {
  userUuid: string;
  email: string;
  password: string;
  goal: GoalTypeAbbr; // Calculated based on weightGoal, startWeight + reason
  reason: GoalTypeAbbr; // What the user picked
  diet: Diet;
  country: string;
  eventDate: string; // not used by API
  activityDegree: ActivityDegree;
  dateOfBirth: string;
  startWeight: number;
  unitMass: UnitMass;
  weightGoal: number;
  sex: Sex;
  bodyHeight: number;
  unitLength: UnitLength;
  incomplete: boolean;
};
