import dayjs from "dayjs"; // Explicit import required for nitrojs usage https://github.com/fumeapp/dayjs/issues/8
import duration from "dayjs/plugin/duration.js"; // Explicit import required for playwright usage https://github.com/iamkun/dayjs/issues/1167
// this rule is a false positive, there is no "extend" named export available right now
// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(duration);

export function usePeriod() {
  function getMonthsFromPeriod(period: string) {
    return dayjs.duration(period).asMonths();
  }
  return {
    getMonthsFromPeriod,
  };
}
