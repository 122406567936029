import type { UserSubscription } from "~/types/user";

export function useUserSubscription() {
  const dayjs = useDayjs();

  function isActive(subscription: UserSubscription) {
    return ["will_renew", "grace_period"].includes(subscription.status);
  }

  function canCancel(subscription: UserSubscription) {
    return isActive(subscription) && ["stripe", "braintree"].includes(subscription.gateway);
  }

  function periodInMonths(subscription: UserSubscription) {
    return dayjs(subscription.end).diff(subscription.start, "month");
  }
  function getExternalSubscriptionManagementUrl(subscription: UserSubscription) {
    if (subscription.gateway === "apple_appstore") {
      return "https://apps.apple.com/account/subscriptions";
    } else if (subscription.gateway === "google_playstore") {
      return "https://play.google.com/store/account/subscriptions";
    }
    return undefined;
  }

  return {
    isActive,
    canCancel,
    periodInMonths,
    getExternalSubscriptionManagementUrl,
  };
}
