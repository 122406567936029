declare global {
  interface Window {
    dataLayer: object[];
  }
}

export function useGTM() {
  const {
    public: { gtmId, gtmEnabled },
  } = useRuntimeConfig();

  function install() {
    if (!gtmId || gtmEnabled === "false") {
      // eslint-disable-next-line
      console.warn("GTM not enabled or gtmId not set, skipping GTM initialization");
      return;
    }

    useHead({
      script: [
        `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmId}');`,
      ],
    });
    useHead(
      {
        noscript: [
          `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        ],
      },
      { tagPosition: "bodyOpen" },
    );
  }

  function trackEvent(eventName: string, eventParams: Record<string, Record<string, string | number>>) {
    if (!gtmId || gtmEnabled === "false") {
      // eslint-disable-next-line
      console.warn("GTM not enabled or gtmId not set, skipping GTM initialization");
      return;
    }

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: eventName,
      ...eventParams,
    });
  }

  return {
    install,
    trackEvent,
  };
}
