export function useUnitsConverter() {
  const KG_IN_POUNDS = 2.20462;

  function feetAndInchesToCm(feet: number, inches: number) {
    return Math.round((feet * 12 + inches) * 2.54);
  }

  function cmToFeetAndInches(value: number) {
    const totalInches = value / 2.54;
    let feet = Math.floor(totalInches / 12);
    let inches = Math.round(totalInches % 12);

    if (inches === 12) {
      feet += 1;
      inches = 0;
    }

    return { feet, inches };
  }

  function kgToPounds(value: number) {
    return Math.round(value * KG_IN_POUNDS * 10) / 10;
  }

  function poundsToKg(pounds: number) {
    return Math.round((pounds / KG_IN_POUNDS) * 10000) / 10000;
  }

  return {
    KG_IN_POUNDS,
    feetAndInchesToCm,
    cmToFeetAndInches,
    kgToPounds,
    poundsToKg,
  };
}
