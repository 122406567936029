import * as Sentry from "@sentry/nuxt";
import { useOnboardingStore } from "~/stores/useOnboardingStore";
import type { I18n } from "~/types/i18n";

export default defineNuxtPlugin((nuxt) => {
  const { $pinia } = useNuxtApp();
  const { registrationParams } = storeToRefs(useOnboardingStore($pinia));
  const { user, userType } = storeToRefs(useUserStore($pinia));
  const i18n = nuxt.$i18n as I18n;
  const biTrackingStore = useBiTrackingStore($pinia);

  function setContext() {
    const context = {
      Sex: registrationParams?.value?.sex || "-",
      Goal: registrationParams?.value?.goal || "-",
      Pro: !!(user?.value && user?.value?.premium_type),
      Country: registrationParams?.value?.country || "-",
      Language: i18n?.locale?.value || "-",
      "Session ID": biTrackingStore?.getSessionId() || "-",
      "User UUID": registrationParams?.value?.userUuid || "-",
      "User Type": userType?.value || "-",
      SSR: !!nuxt.ssrContext,
    };
    Sentry.setContext("User Context", context);
  }

  if (!nuxt.ssrContext) {
    setContext();

    watchEffect(() => {
      setContext();
    });
  }
});
