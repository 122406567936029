export function useAge() {
  const dayjs = useDayjs();

  function getAge(dateOfBirth: string): number {
    return dayjs().diff(dateOfBirth, "year");
  }

  function getMaxBirthdate(): string {
    return dayjs().subtract(15, "year").set("hours", 0).set("minutes", 0).set("seconds", 0).format("YYYY-MM-DD");
  }

  function getMinBirthdate(): string {
    return dayjs().subtract(200, "year").set("hours", 0).set("minutes", 0).set("seconds", 0).format("YYYY-MM-DD");
  }

  return {
    getAge,
    getMaxBirthdate,
    getMinBirthdate,
  };
}
