export default defineAppConfig({
  goalKgPerWeek: 0.5,
  goalLbPerWeek: 1.1,
  cookieMaxAgeH: 72,
  trialDays: 7,
  authCookieMaxAgeD: 30,
  onboardingLoadingPersonalPlanMS: [3500, 2500, 2500, 2500],
  enabledFunnels: ["partner", "test", "welcome", "calculator"],
  awin: {
    defaultMerchantId: "29533",
    basketFreezeOverrideList: [
      "647297",
      "1265723",
      "1073895",
      "852688",
      "786479",
      "781237",
      "716289",
      "637873",
      "544429",
      "430631",
      "426667",
      "403267",
      "400137",
      "334031",
      "333885",
      "332747",
      "332727",
      "332701",
      "332681",
      "332677",
      "328727",
      "320221",
      "228811",
      "196639",
      "320119",
      "1237770",
      "1207960",
      "1164654",
      "1052075",
      "826657",
      "822461",
      "809055",
      "677893",
      "654885",
      "624495",
      "544429",
      "518347",
      "213861",
      "196639",
      "137879",
    ],
  },
});

declare module "nuxt/schema" {
  interface AppConfig {
    goalKgPerWeek: number;
    goalLbPerWeek: number;
    segment: string;
    cookieMaxAgeH: number;
    trialDays: number;
    authCookieMaxAgeD: number;
    awin: {
      defaultMerchantId: string;
      basketFreezeOverrideList: string[];
    };
  }
}
