export function useTranslationFormatter() {
  const formatLink = (input: string, targetBlank?: string, link?: { [key: string]: string }) => {
    const matches = input.match(/\[.*?]/g);

    if (matches !== null && matches.length > 0) {
      return input.replace(/\[.*?]/g, (match) => {
        const text = match.replace(/[[\]]/g, "").split("---");
        let newLink = `<a href="${text[1]}"${targetBlank ? ' target="_blank"' : ""}>${text[0]}</a>`;
        if (link) {
          for (const replaceKey in link) {
            newLink = newLink.replace(replaceKey, link[replaceKey]);
          }
        }

        return newLink;
      });
    }

    return input;
  };

  const fillPlaceholderValues = (translation: string, placeholderValues: string[]): string => {
    const placeholderRegex = /%(\d+\$)?s/g;

    return translation.replace(placeholderRegex, (_, placeholderIndex) => {
      const index = parseInt(placeholderIndex || 1) - 1;
      if (index < 0 || index >= placeholderValues.length) {
        throw new Error("Invalid number of placeholders or values.");
      }

      return placeholderValues[index];
    });
  };

  return { formatLink, fillPlaceholderValues };
}
