import type { LocationQuery } from "vue-router";
import { z } from "zod";

export interface QueryParameters {
  has<S extends string>(
    k: S,
  ): this is (string extends S ? object : { get(k: S): string; getAndRemove(k: S): string }) & this;
  has(key: string): boolean;
  hasAll<S extends string>(
    keys: S[],
  ): this is (string extends S ? object : { get(k: S): string; getAndRemove(k: S): string }) & this;
  hasAll(keys: string[]): boolean;
  get(key: string): string | undefined;
  getAndRemove(key: string): string | undefined;
  parseAndRemove<S extends z.ZodTypeAny>(key: string, schema: S): z.infer<S> | undefined;
  parse<S extends z.ZodTypeAny>(key: string, schema: S): z.infer<S> | undefined;
  remove(key: string): void;
  hasParameters(): boolean;
  getAndRemoveAll(keys: string[]): string | undefined;
  haveParametersChanged(): boolean;
  getRemainingParameters(): Record<string, string>;
  sessionShouldReset(): boolean;
  isNewFlow(): boolean;
}

export const useQueryParameters = (locationQuery: LocationQuery): QueryParameters => {
  const params = flattenQueryParameters(locationQuery);

  const initialParams = new Map(Object.entries(params));
  const remainingParams = new Map(Object.entries(params));

  const has = (key: string) => initialParams.has(key);

  const hasAll = (keys: string[]) => keys.every((key) => has(key));

  const get = (key: string) => initialParams.get(key);

  const parse = <S extends z.ZodTypeAny>(key: string, schema: S): z.infer<S> | undefined => {
    const value = get(key);

    if (value !== undefined) {
      const parsedValue = schema.safeParse(value);
      return parsedValue.success ? parsedValue.data : undefined;
    }
  };

  const remove = (key: string) => remainingParams.delete(key);

  const getAndRemove = (key: string) => {
    remove(key);
    return get(key);
  };

  const parseAndRemove = <S extends z.ZodTypeAny>(key: string, schema: S): z.infer<S> | undefined => {
    const value = getAndRemove(key);

    if (value !== undefined) {
      const parsedValue = schema.safeParse(value);
      return parsedValue.success ? parsedValue.data : undefined;
    }
  };

  const getAndRemoveAll = (keys: string[]) => {
    return keys.map((key) => getAndRemove(key)).find((value) => value !== undefined);
  };

  const hasParameters = () => initialParams.size > 0;

  const haveParametersChanged = () => initialParams.size !== remainingParams.size;

  const getRemainingParameters = () => Object.fromEntries(remainingParams);

  const sessionShouldReset = () => initialParams.has("utm_source");

  const isNewFlow = () => initialParams.has("flowid");

  return {
    // @ts-ignore
    has,
    // @ts-ignore
    hasAll,
    get,
    parse,
    remove,
    getAndRemove,
    parseAndRemove,
    getAndRemoveAll,
    hasParameters,
    haveParametersChanged,
    getRemainingParameters,
    sessionShouldReset,
    isNewFlow,
  };
};
