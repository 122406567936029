import { z } from "zod";

export const GoalTypeSchema = z.enum(["LooseWeight", "MaintainWeight", "GainWeight", "BuildMuscle"]);
export const GoalTypeAbbrSchema = z.enum(["lose", "maintain", "gain", "build_muscle"]);

export type GoalType = z.infer<typeof GoalTypeSchema>;
export type GoalTypeAbbr = z.infer<typeof GoalTypeAbbrSchema>;

export const goalMap: { [key in GoalType]: GoalTypeAbbr } = {
  LooseWeight: "lose",
  MaintainWeight: "maintain",
  GainWeight: "gain",
  BuildMuscle: "build_muscle",
};
