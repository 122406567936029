import { z } from "zod";
import type {
  ActivityDegree,
  Diet,
  Sex,
  UnitEnergy,
  UnitGlucose,
  UnitLength,
  UnitMass,
  UnitServing,
} from "~/types/onboarding/registrationParams";
import { type GoalTypeAbbr, GoalTypeAbbrSchema } from "~/types/onboarding/onboardingGoal";
import { UnitMassSchema } from "~/types/onboarding/registrationParams";

export const UserSubscriptionTypeSchema = z.enum(["subscription", "lifetime"]).nullable();
export type UserSubscriptionType = z.infer<typeof UserSubscriptionTypeSchema>;

export const UserAccountAPISchema = z.object({
  uuid: z.string(),
  goal: GoalTypeAbbrSchema,
  email: z.string(),
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
  premium_type: UserSubscriptionTypeSchema,
  country: z.string().nullable(),
  stripe_customer_id: z.string().nullable().optional(),
  user_token: z.string(),
  profile_image: z.string().nullable(),
  unit_mass: UnitMassSchema,
  start_weight: z.number(),
});

export type UserAccountAPI = z.infer<typeof UserAccountAPISchema>;

export const UserAccountSchema = UserAccountAPISchema.extend({
  country: z.string(),
  goal_weight: z.number(),
});

export type UserAccount = z.infer<typeof UserAccountSchema>;

export const UserGoalsSchema = z.object({
  "energy.energy": z.number(),
  water: z.number(),
  "activity.step": z.number(),
  "nutrient.protein": z.number(),
  "nutrient.fat": z.number(),
  "nutrient.carb": z.number(),
  "bodyvalue.weight": z.number().nullable().optional(),
});

export interface UserGoals extends z.infer<typeof UserGoalsSchema> {}

export interface UpdateUserGoalsParams {
  date: string;
  goals: Partial<UserGoals>;
}

export interface RegisterUserParams {
  uuid: string;
  language: string;
  country: string;
  timezone_offset: number;
  activity_degree: ActivityDegree;
  sex: Sex;
  weight_change_per_week: number;
  body_height: number;
  start_weight: number;
  weight_goal: number | null;
  date_of_birth: string;
  registration_device: "web";
  unit_length: UnitLength;
  unit_mass: UnitMass;
  unit_energy: UnitEnergy;
  unit_glucose: UnitGlucose;
  unit_serving: UnitServing;
  goal: GoalTypeAbbr;
  energy_goal: number;
  auth: {
    type: "credentials";
    email: string;
    password: string;
  };
  calorie_goal_override_mode: string | null;
  dietary_preference: Diet;
}

export interface UpdateUserParams {
  country: string;
  stripe_customer_id: string;
  unit_length: UnitLength;
  unit_mass: UnitMass;
  body_height: number;
  start_weight: number;
  date_of_birth: string;
  sex: string;
  goal: GoalTypeAbbr;
}

export interface UpdatePasswordParams {
  current_password: string;
  new_password: string;
}

export interface UserSubscription {
  start: string;
  end: string;
  gateway: "google_playstore" | "apple_appstore" | "stripe" | "braintree" | "internal";
  type: string;
  status: "unknown" | "cancelled" | "expired" | "grace_period" | "will_renew" | "will_expire";
  payment_provider_transaction_id: string;
  last_status_change_at: string;
}

export interface CancelSubscriptionParams {
  payment_provider_transaction_id: string;
}
