import type { LocationQuery } from "vue-router";

/**
 * Flattens the query parameters object to a simple Record<string, string>, removing null values.
 * When multiple values are present for a key, the last one is kept.
 */
export function flattenQueryParameters(query: LocationQuery): Record<string, string> {
  return Object.entries(query).reduce(
    (acc, [key, value]) => {
      const lastEntry = Array.isArray(value) ? value[value.length - 1] : value;

      if (lastEntry !== null) {
        acc[key] = lastEntry;
      }

      return acc;
    },
    {} as Record<string, string>,
  );
}
