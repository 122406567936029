import { defineStore } from "pinia";
import { DaysToSeconds } from "@ilihub/time";
import appConfig from "~/app.config";
import type { QueryParameters } from "~/composables/useQueryParameters";

export const useAuthStore = defineStore(
  "auth",
  () => {
    const { $pinia } = useNuxtApp();
    const tokenUserStore = useTokenUserStore($pinia);

    const { getAccessToken } = useAuthApiClient();

    const accessToken = ref<string>();
    const refreshToken = ref<string>();

    async function refreshAuthTokens() {
      if (!refreshToken.value) {
        return;
      }
      const { accessToken: a, refreshToken: r } = await getAccessToken({
        refresh_token: refreshToken.value,
        grant_type: "refresh_token",
      });

      accessToken.value = a;
      refreshToken.value = r;
    }

    async function login(username: string, password: string) {
      const { accessToken: a, refreshToken: r } = await getAccessToken({
        username,
        password,
        grant_type: "password",
      });

      // reset tokenUserStore to avoid conflicts
      tokenUserStore.$reset();

      accessToken.value = a;
      refreshToken.value = r;
    }

    function $reset() {
      accessToken.value = undefined;
      refreshToken.value = undefined;
    }

    async function signInWithGoogle(googleToken: string) {
      const { accessToken: a, refreshToken: r } = await getAccessToken({
        id_token: googleToken,
        grant_type: "sign_in_with_google",
      });

      accessToken.value = a;
      refreshToken.value = r;
    }

    async function signInWithApple(appleToken: string) {
      const { accessToken: a, refreshToken: r } = await getAccessToken({
        jwt: appleToken,
        grant_type: "sign_in_with_apple",
      });

      accessToken.value = a;
      refreshToken.value = r;
    }

    function handleQueryParameters(queryParameters: QueryParameters) {
      if (queryParameters.isNewFlow()) {
        $reset();
      }
    }

    return {
      $reset,
      accessToken,
      refreshToken,
      refreshAuthTokens,
      login,
      signInWithGoogle,
      signInWithApple,
      handleQueryParameters,
    };
  },
  {
    persist: {
      key: "auth",
      storage: persistedState.cookiesWithOptions({
        maxAge: DaysToSeconds(appConfig.authCookieMaxAgeD),
      }),
    },
  },
);
