export default defineNuxtRouteMiddleware(async () => {
  const { $pinia } = useNuxtApp();
  const userStore = useUserStore($pinia);
  const authStore = useAuthStore($pinia);
  const tokenUserStore = useTokenUserStore($pinia);

  if (!userStore.user && authStore.accessToken) {
    await userStore.fetchUser();
    authStore.$persist(); // for the case when the token has been refreshed
  }

  if (tokenUserStore.token && !tokenUserStore.tokenUser) {
    await tokenUserStore.fetchTokenUser();
  }
});
