
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'

import 'dayjs/locale/cs'
import 'dayjs/locale/da'
import 'dayjs/locale/de'
import 'dayjs/locale/el'
import 'dayjs/locale/en'
import 'dayjs/locale/es'
import 'dayjs/locale/fi'
import 'dayjs/locale/fr'
import 'dayjs/locale/hu'
import 'dayjs/locale/it'
import 'dayjs/locale/ja'
import 'dayjs/locale/ko'
import 'dayjs/locale/nb'
import 'dayjs/locale/nl'
import 'dayjs/locale/pl'
import 'dayjs/locale/pt'
import 'dayjs/locale/ru'
import 'dayjs/locale/sv'
import 'dayjs/locale/tr'
import 'dayjs/locale/zh'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import relativeTime from 'dayjs/plugin/relativeTime'


dayjs.extend(updateLocale)

dayjs.extend(localeData)
dayjs.extend(localizedFormat)
dayjs.extend(utc)
dayjs.extend(customParseFormat)
dayjs.extend(relativeTime)



// defaultLocale: "en"


dayjs.updateLocale("en")
dayjs.locale('en')


export default dayjs
