import { ref } from "vue";
import { defineStore } from "pinia";
import { DaysToSeconds } from "@ilihub/time";
import { useBackendApiContentDomainClient } from "~/composables/api/useBackendApiContentDomainClient";

const DEFAULT_SEGMENT = "1";

export const useDiscountStore = defineStore(
  "discount",
  () => {
    const backendApiClient = useBackendApiAppDomainClient();
    const frontendApiClient = useBackendApiFrontendDomainClient();
    const contentApiClient = useBackendApiContentDomainClient();

    const code = ref<string>("");
    const partnerContentOverride = ref<string>("");

    const partnerContentCode = computed(() => partnerContentOverride.value || code.value);

    const {
      data: partnerContent,
      execute: executeGetPartnerContent,
      status: partnerContentStatus,
    } = contentApiClient.getPartnerContentByDiscountCode(partnerContentCode);

    async function getActiveSegment(): Promise<string> {
      if (!code.value) {
        return DEFAULT_SEGMENT;
      }

      try {
        const segmentData = await backendApiClient.getValidVoucherCodeDetails(code.value);
        return segmentData.segment ?? DEFAULT_SEGMENT;
      } catch (error) {
        return DEFAULT_SEGMENT;
      }
    }

    async function getCurrentDiscount(): Promise<number> {
      if (!code.value) {
        return 0;
      }

      const nuxtApp = useNuxtApp();
      const { getMonthsFromPeriod } = usePeriod();

      const { locale } = nuxtApp.$i18n;
      const country = useCountry().getCountry();
      const activeSegment = await nuxtApp.runWithContext(() => getActiveSegment());

      if (activeSegment === DEFAULT_SEGMENT) {
        return 0;
      }

      const data = await nuxtApp.runWithContext(() =>
        frontendApiClient.getAvailableSubscriptionPlansFetch({
          country,
          locale: locale.value,
          segment: activeSegment,
        }),
      );

      const plans = data
        .filter((product) => product.type === "subscription")
        .sort((a, b) => getMonthsFromPeriod(b.period) - getMonthsFromPeriod(a.period));

      const plan = plans.find((plan) => plan.strike_price_raw !== null);

      if (!plan || !plan.strike_price_raw) {
        return 0;
      }

      return Math.round(((plan.strike_price_raw - plan.price_raw) / plan.strike_price_raw) * 100);
    }

    function handleQueryParameters(queryParameters: QueryParameters) {
      if (queryParameters.sessionShouldReset()) {
        $reset();
      }

      code.value = queryParameters.getAndRemoveAll(["code", "sid", "partner", "coupon"]) ?? code.value;
      partnerContentOverride.value = queryParameters.getAndRemove("partnerOverride") ?? partnerContentOverride.value;
    }

    function $reset() {
      code.value = "";
      partnerContentOverride.value = "";
    }

    return {
      code,
      partnerContent,
      partnerContentStatus,
      partnerContentOverride,
      executeGetPartnerContent,
      getCurrentDiscount,
      getActiveSegment,
      handleQueryParameters,
      $reset,
    };
  },
  {
    persist: {
      pick: ["code", "partnerContentOverride"],
      storage: persistedState.cookiesWithOptions({
        maxAge: DaysToSeconds(1),
      }),
    },
  },
);
