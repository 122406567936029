<script setup lang="ts">
import { useTrackingClient } from "~/composables/api/tracking/useTrackingClient";
import { useTrackingStore } from "~/stores/useTrackingStore";

const trackingStore = useTrackingStore();
const { trackPurchase, trackImpression, trackAction } = useTrackingClient();

watch(
  () => trackingStore.purchaseEvents,
  () => {
    if (!trackingStore.hasPurchaseEvents()) {
      return;
    }

    trackingStore.purchaseEvents.forEach((purchaseEvent) => {
      trackPurchase(purchaseEvent);
    });

    trackingStore.clearPurchaseEvents();
  },
  { deep: true, immediate: true },
);

watch(
  () => trackingStore.impressionEvents,
  () => {
    if (!trackingStore.hasImpressionEvents()) {
      return;
    }

    trackingStore.impressionEvents.forEach((impressionEvent) => {
      trackImpression(impressionEvent);
    });

    trackingStore.clearImpressionEvents();
  },
  { deep: true, immediate: true },
);

watch(
  () => trackingStore.actionEvents,
  () => {
    if (!trackingStore.hasActionEvents()) {
      return;
    }

    trackingStore.actionEvents.forEach((actionEvent) => {
      trackAction(actionEvent);
    });

    trackingStore.clearActionEvents();
  },
  { deep: true, immediate: true },
);
</script>

<template>
  <slot />
</template>
