import { encodeBase64 } from "~/utils/base64";

export function useBackendApiFrontendDomainConfig() {
  const config = useRuntimeConfig();

  const basicAuthHeader =
    "Basic " + encodeBase64(config.backendApiBasicAuthUsername + ":" + config.backendApiBasicAuthPassword);

  const baseUrl = config.backendApiFrontendDomainBaseUrl;
  const apiBaseUrl = config.public.backendApiFrontendDomainApiBaseUrl;

  return { basicAuthHeader, baseUrl, apiBaseUrl };
}
