export function useBMI() {
  function getBMI(weightInKg: number, heightInCm: number) {
    const heightInMeters = heightInCm / 100;
    const bmi = weightInKg / (heightInMeters * heightInMeters);

    // Return the BMI rounded to two decimal places
    return parseFloat(bmi.toFixed(2));
  }

  function getWeightForBmiAndHeight(bmi: number, heightInCm: number) {
    const heightInMeters = heightInCm / 100;
    const weight = bmi * heightInMeters * heightInMeters;
    return parseFloat(weight.toFixed(1));
  }

  return {
    getBMI,
    getWeightForBmiAndHeight,
  };
}
