import { ref } from "vue";
import { defineStore } from "pinia";
import { DaysToSeconds } from "@ilihub/time";
import type { QueryParameters } from "~/composables/useQueryParameters";

type FunnelVariant = "default" | "alternative";

export const useFunnelStore = defineStore(
  "funnel",
  () => {
    const variant = ref<FunnelVariant>("default");

    function $reset() {
      variant.value = "default";
    }

    function isAlternativeVariant() {
      return variant.value === "alternative";
    }

    function handleQueryParameters(query: QueryParameters) {
      if (query.has("funnel_variant")) {
        variant.value = query.getAndRemove("funnel_variant") === "default" ? "default" : "alternative";
      }
    }

    return {
      variant,
      isAlternativeVariant,
      handleQueryParameters,
      $reset,
    };
  },
  {
    persist: {
      storage: persistedState.cookiesWithOptions({
        maxAge: DaysToSeconds(30),
      }),
    },
  },
);
