import type { MaybeRef } from "vue";
import type {
  GetSubscriptionPlansParams,
  SubscriptionPlan,
  UserDetails,
  ValidateEmailParams,
} from "~/types/backendApiClient";
import { useBackendApiFrontendDomainConfig } from "~/composables/api/useBackendApiFrontendDomainConfig";
import { UserDetailsSchema } from "~/types/backendApiClient";

export function useBackendApiFrontendDomainClient() {
  const { apiBaseUrl } = useBackendApiFrontendDomainConfig();
  const { fetch, nuxtFetch } = useRequest(apiBaseUrl);
  const { getMonthsFromPeriod } = usePeriod();

  function getAvailableSubscriptionPlans(
    query: MaybeRef<GetSubscriptionPlansParams | undefined>,
    immediate: boolean = false,
  ) {
    return nuxtFetch<SubscriptionPlan[]>("/api/frontend/product", {
      query,
      immediate,
      transform: (res) =>
        res
          .filter((product) => product.type === "subscription")
          .sort((a, b) => getMonthsFromPeriod(b.period) - getMonthsFromPeriod(a.period)),
    });
  }

  function getAvailableSubscriptionPlansFetch(query: MaybeRef<GetSubscriptionPlansParams | undefined>) {
    return fetch<SubscriptionPlan[]>("/api/frontend/product", {
      query,
    });
  }

  function validateEmail(query: ValidateEmailParams) {
    return fetch("/api/frontend/user/validate-email", {
      query,
    });
  }

  function resetPassword(password: string, token: string) {
    return fetch("/api/frontend/user/reset-password", {
      method: "post",
      body: {
        token,
        new_password: password,
      },
    });
  }

  function deleteAccount(token: string) {
    return fetch("/api/frontend/user", {
      method: "delete",
      body: {
        token,
      },
    });
  }

  function unsubscribeNewsletter(unsubscribe_token: string) {
    return fetch("/api/frontend/user/newsletter-unsubscribe", {
      method: "delete",
      body: {
        unsubscribe_token,
      },
    });
  }

  function getUserDetails(userToken: MaybeRef<string>, immediate: boolean = false) {
    return nuxtFetch<UserDetails>("/api/frontend/user/get-user-details", {
      query: {
        user_token: userToken,
      },
      immediate,
      transform: (res) => UserDetailsSchema.parse(res),
    });
  }

  function confirmEmail(token: string) {
    return fetch("/api/frontend/user/confirm-email", {
      method: "post",
      body: {
        token,
      },
    });
  }

  return {
    getAvailableSubscriptionPlans,
    getAvailableSubscriptionPlansFetch,
    validateEmail,
    resetPassword,
    deleteAccount,
    unsubscribeNewsletter,
    getUserDetails,
    confirmEmail,
  };
}
