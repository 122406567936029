interface LocaleFontConfig {
  [key: string]: string;
}

const localeFontConfig: LocaleFontConfig = {
  cs: "latin-ext",
  da: "latin",
  de: "latin",
  el: "greek",
  en: "latin",
  es: "latin",
  fr: "latin",
  fi: "latin",
  hu: "latin-ext",
  it: "latin",
  ja: "jp",
  ko: "ko",
  nb: "latin",
  nl: "latin",
  pl: "latin-ext",
  pt: "latin-ext",
  ru: "cryllic",
  sv: "latin",
  tr: "latin-ext",
  zh: "cn",
  default: "latin",
};

export default localeFontConfig;
